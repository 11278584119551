import './webauthn';

import '@hotwired/turbo-rails';

import * as ActiveStorage from "@rails/activestorage";
ActiveStorage.start();

import "trix"
import "@rails/actiontext"
window.Trix = Trix;

import LocalTime from "local-time";
LocalTime.start();
window.LocalTime = LocalTime;

import Alpine from "alpinejs"
window.Alpine = Alpine
Alpine.start()

import TomSelect from "tom-select";
window.TomSelect = TomSelect;
import "tom-select/dist/css/tom-select.css";

document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll(".apply-tom-select-multiple-with-create").forEach((elem) => {
    new TomSelect(elem, {
      hideSelected: false,
      create: true,
      createFilter: function (input) {
        return !(input in this.options);
      },
      plugins: {
        caret_position: {},
        input_autogrow: {},
        clear_button: { title: "Remove all selected options" },
        remove_button: { title: "Remove this item" },
      },
    });
  });

  document.querySelectorAll(".apply-tom-select-multiple").forEach((elem) => {
    new TomSelect(elem, {
      hideSelected: false,
      plugins: {
        caret_position: {},
        input_autogrow: {},
        clear_button: { title: "Remove all selected options" },
        remove_button: { title: "Remove this item" },
      },
    });
  });

  document.querySelectorAll(".apply-tom-select-single-with-create").forEach((elem) => {
    new TomSelect(elem, {
      maxItems: 1,
      hideSelected: false,
      create: true,
      createFilter: function (input) {
        return !(input in this.options);
      },
    });
  });

  document.querySelectorAll(".apply-tom-select-single").forEach((elem) => {
    new TomSelect(elem, {
      maxItems: 1,
      hideSelected: false,
    });
  });
});
